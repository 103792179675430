import React from "react";

const Pricing = () => {
  return (
    <div>
      <h1>Pricing Plans</h1>
      <p>$30/month - Unlimited Chat & Voice</p>
    </div>
  );
};

export default Pricing; // ✅ Ensure this is a default export