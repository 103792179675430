import React from "react";

const FAQ = () => {
  return (
    <div>
      <h1>Frequently Asked Questions</h1>
      <p>How do I start chatting? Click "Start Chatting" on the homepage.</p>
    </div>
  );
};

export default FAQ;