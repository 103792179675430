import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Welcome to My Personal Angel</h1>
      <p>Chat with your own AI-powered Angel for guidance.</p>
    </div>
  );
};

export default Home; // ✅ Ensure it's a default export