import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import "./Chat.css";
import angels from "./angels";

const Chat = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedAngel, setSelectedAngel] = useState(angels[0]); // Default to Gabriel
  const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);
  const recognition = useRef(null);

  // ✅ Wrap `sendMessage` in `useCallback`
  const sendMessage = useCallback(async (messageText = input) => {
    if (!messageText.trim()) return;

    const newMessages = [...messages, { user: messageText }];
    setMessages(newMessages);
    setInput("");
    setIsTyping(true);

    // Convert user input to lowercase for better matching
    const lowerInput = messageText.toLowerCase();

    // Find the requested Angel, checking both exact names and aliases
    const requestedAngel = angels.find(angel =>
      lowerInput.includes(angel.name.toLowerCase()) ||
      lowerInput.includes(angel.id.toLowerCase()) ||
      (angel.aliases && angel.aliases.some(alias => lowerInput.includes(alias)))
    );

    // If a valid Angel name (or alias) is found, switch to that Angel
    if (requestedAngel) {
      setSelectedAngel(requestedAngel); // Update the dropdown selection
      setMessages(prevMessages => [...prevMessages, { angel: `You are now speaking with ${requestedAngel.name}.` }]);
      setIsTyping(false);
      return; // Stop further processing
    }

    // If user says "Next Angel"
    if (lowerInput.includes("next angel")) {
      const currentIndex = angels.findIndex(angel => angel.id === selectedAngel.id);
      const nextIndex = (currentIndex + 1) % angels.length;
      setSelectedAngel(angels[nextIndex]);
      setMessages(prevMessages => [...prevMessages, { angel: `You are now speaking with ${angels[nextIndex].name}.` }]);
      setIsTyping(false);
      return;
    }

    try {
      const response = await axios.post("https://my-personal-angel-backend-hu35.vercel.app/", { 
        message: messageText,
        personality: selectedAngel.personality // Send the selected Angel's personality
      });

      setTimeout(() => {
        const angelReply = response.data.reply;
        setMessages(prevMessages => [...prevMessages, { angel: angelReply }]);
        setIsTyping(false);

        // 🎙️ Play the AI-generated voice
        if (response.data.audio) {
          const audio = new Audio(`data:audio/mpeg;base64,${response.data.audio}`);
          audio.play();
        }
      }, 1500);

    } catch (error) {
      console.error("Error sending message:", error);
      setMessages(prevMessages => [...prevMessages, { angel: "Sorry, I'm having trouble responding right now." }]);
      setIsTyping(false);
    }
  }, [input, messages, selectedAngel]); // ✅ Add dependencies to `sendMessage`

  // ✅ Wrap `handleVoiceCommand` in `useCallback`
  const handleVoiceCommand = useCallback((spokenText) => {
    console.log("🎤 Voice Input Detected:", spokenText);
    sendMessage(spokenText);
  }, [sendMessage]); // ✅ Now includes `sendMessage` as a dependency

  // ✅ Speech Recognition Setup
  useEffect(() => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      console.warn("Speech recognition is not supported in this browser.");
      return;
    }

    recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.current.continuous = false;
    recognition.current.interimResults = false;
    recognition.current.lang = "en-US";

    recognition.current.onresult = (event) => {
      try {
        const spokenText = event.results[0][0].transcript.toLowerCase();
        handleVoiceCommand(spokenText);
      } catch (error) {
        console.error("🚨 Error processing speech:", error);
      }
    };

    // ✅ Handle microphone errors
    recognition.current.onerror = (event) => {
      console.error("❌ Speech recognition error:", event.error);
    };

    recognition.current.onspeechend = () => {
      console.log("🎤 Speech ended, stopping recognition.");
      recognition.current.stop();
    };

  }, [handleVoiceCommand]); // ✅ Now includes `handleVoiceCommand`

  // ✅ Function to Start Voice Recognition
  const startListening = () => {
    if (recognition.current) {
      console.log("🎤 Microphone activated! Listening...");
      recognition.current.start();
    }
  };

  // ✅ Auto-scroll to the latest message
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isTyping]);

  return (
    <div className="chat-container">
      <div className="angel-selection">
        <label>Select Your Angel: </label>
        <select
          value={selectedAngel.id}
          onChange={(e) => setSelectedAngel(angels.find(a => a.id === e.target.value))}
        >
          {angels.map((angel) => (
            <option key={angel.id} value={angel.id}>
              {angel.name} – {angel.description}
            </option>
          ))}
        </select>
      </div>

      <div className="chat-header">😇 Chat with Your Angel</div>

      <div className="chat-messages">
        {messages.map((msg, idx) => (
          <div key={idx} className={`message ${msg.user ? "user-message" : "angel-message"}`}>
            <strong>{msg.user ? "You: " : "Angel: "}</strong> {msg.user || msg.angel}
          </div>
        ))}

        {/* Show "Angel is typing..." effect */}
        {isTyping && <div className="message angel-message">✨ Angel is typing...</div>}

        <div ref={chatEndRef} />
      </div>

      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && sendMessage(input)} // ✅ Pass input as argument
          placeholder="Type your message..."
        />
        <button onClick={() => sendMessage(input)}>Send</button>
        <button onClick={startListening}>🎤 Speak</button> {/* 🎤 New Voice Command Button */}
      </div>
    </div>
  );
};

export default Chat;
