import React from "react";

const About = () => {
  return (
    <div>
      <h1>About My Personal Angel</h1>
      <p>AI-powered spiritual guidance, inspired by mythology.</p>
    </div>
  );
};

export default About;