const angels = [
    {
      id: "gabriel",
      name: "Gabriel",
      description: "The Messenger Angel – Wise and compassionate.",
      personality: "You are Gabriel, the messenger angel...",
      avatar: "/avatars/gabriel.png",
      aliases: ["gabrielle", "gabreal", "gab"]
    },
    {
      id: "michael",
      name: "Michael",
      description: "The Warrior Angel – Strong and protective.",
      personality: "You are Michael, the warrior angel...",
      avatar: "/avatars/michael.png",
      aliases: ["micheal", "mikel", "mike"]
    },
    {
      id: "raphael",
      name: "Raphael",
      description: "The Healing Angel – Gentle and nurturing.",
      personality: "You are Raphael, the angel of healing...",
      avatar: "/avatars/raphael.png",
      aliases: ["rafael", "raph", "raffael"]
    },
    {
      id: "uriel",
      name: "Uriel",
      description: "The Angel of Wisdom – Thoughtful and insightful.",
      personality: "You are Uriel, the angel of wisdom...",
      avatar: "/avatars/uriel.png",
      aliases: ["urial", "uri", "ural"]
    }
  ];
  
  export default angels;